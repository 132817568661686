<template>

	<div
		v-if="items.length > 0"
		class=" position-relative  "
	>
		<div class="position-absolute full-height pa-20-pc arrow-left flex-column justify-center">
			<button
				@click="prev"
				class="bg-white icon-32 shadow-circle"
			><v-icon>mdi mdi-chevron-left</v-icon></button>
		</div>

		<div class="box-main-banner position-relative radius-20 box">
			<ul
				class="position-relative"
				:style="'left: ' + (banner_index * -100) + '%'"
			>
				<li
					v-for="(banner, b_index) in items"
					:key="'banner_' + b_index"
					class="inline-block banner-img position-relative"
				>
					<div
						class="width-100 banner-bg-img"
						:style="'background-image: url(' + banner.file_path + ');'"
					>
					</div>
				</li>
			</ul>
			<div
				class="position-absolute width-100 pa-10 justify-center gap-10"
				style="left: 0; bottom: 0;"
			>
				<button
					v-for="dot in items.length"
					:key="dot"

					@click="banner_index = dot-1"
				>
					<img
						v-if="banner_index == dot - 1"
						class="shadow-circle"
						:src="require('@/assets/images/main/icon-dot-green-24.svg')" />
					<img
						v-else
						class="shadow-circle"
						:src="require('@/assets/images/main/icon-dot-white-22.svg')" style="width: 10px"/>
				</button>
			</div>
		</div>
		<div class="position-absolute full-height pa-20-pc arrow-right flex-column justify-center">

			<button
				class="bg-white icon-32 shadow-circle"
				@click="next"
			><v-icon>mdi mdi-chevron-right</v-icon></button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Banner'
		, props: ['user', 'codes']
		, data: function(){
			return{
				items: []
				, item: {}
				, banner_index: 0
				, is_move: false
			}
		}
		, computed: {
			
		}
		, methods: {
			getData: async function(){
				try{
					const result = await this.$request.init({
						method: 'get'
						,url: 'main/getMainBanner'
						,data: {
						}
					})

					if(result.success){
						this.items = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, banner_next: function(){

			}

			, prev: function(){
				if(!this.is_move){
					this.is_move = true
					this.banner_index--
					if(this.banner_index < 0){
						this.banner_index = this.items.length - 1
					}
					setTimeout( () => {
						this.is_move = false
					}, 500)
				}
			}
			, next: function(){
				if(!this.is_move) {
					this.is_move = true
					this.banner_index++
					if (this.banner_index >= this.items.length) {
						this.banner_index = 0
					}
					setTimeout(() => {
						this.is_move = false
					}, 500)
				}
			}
		}
		, created() {
			this.getData()

			setInterval( () => {
				this.next()
			}, 5000)
		}
	}
</script>

<style>

	@media (max-width: 720px) {
		.box-main-banner {
			width: 100%; height: 120px;
			overflow: hidden;
			white-space: nowrap;
		}
		.box-main-banner > ul  {
			transition: left 0.5s;
		}

		.box-main-banner li {
			width: 100%;
			height: 180px;
		}

		.banner-bg-img {
			background-position: center center; background-size: cover; width: 100%; height: 120px;
		}

		.arrow-right {
			top: 0; right: -10.5px
		}
		.arrow-left {
			top: 0; left: -12.5px; z-index: 1;
		}
	}
	@media (min-width: 720px) {
		.box-main-banner {
			width: 100%; height: 400px;
			overflow: hidden;
			white-space: nowrap;
		}
		.box-main-banner > ul  {
			transition: left 0.5s;
		}

		.box-main-banner li {
			width: 100%;
			height: 400px;
		}

		.banner-bg-img {
			background-position: center center; background-size: cover; width: 100%; height: 400px;
		}


		.arrow-right {
			top: 0; right: 0
		}
		.arrow-left {
			top: 0; left: 0; z-index: 1;
		}
	}

	.banner-on {
		left: 0
	}
	.banner-off {
		left: -100%
	}
</style>